<template>
    <div style="width:100%; height: 100vh;">
        <sidebar v-for="node in nodes" :key="node.imei" :node="node" :snow_images="snow_images" :normal_images="normal_images" :ref="'sidebar_'+ node.imei">
        </sidebar>

        <div class="map">
            <l-map id="stations-map"
                   :options="{zoomControl:false}"
                   :zoom="currentZoom"
                   :center="currentCenter"
                   @update:zoom="zoomUpdated"
                   @update:center="centerUpdated">



                <l-marker v-for="node in nodes" :key="node.id"
                    :lat-lng="[node.lat, node.lng]"
                    :icon="icon"
                    @click="openSidebar(node)">
                </l-marker>

                <l-tile-layer :url="url"></l-tile-layer>
                <l-control-zoom position="topleft"></l-control-zoom>

            </l-map>
        </div>
    </div>
</template>

<script>
import markerImage from "../../../public/images/location.svg"
import L, {latLng} from 'leaflet';
import {LMap, LTileLayer, LMarker, LControlZoom} from 'vue2-leaflet';

function showModal(imei) {
    $('#' + imei).modal();
}

export default {
    name: 'TheMap',
    data: function () {
        return {
            center: L.latLng(38.70305624916754, 23.717504267390936),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?apikey=e955b6c9bc0b4ec6a38495e3f90d87b5',
            currentCenter: this.getLatLng(),
            currentZoom: this.getZoom(),
            markerImage: markerImage,
            icon: L.icon({
                iconUrl: markerImage,
                iconSize: [32, 37],
                iconAnchor: [16, 29]
            }),
            nodes: [],
            offIcon: null,
            keyword: '',
            myPagedMarkers: [],
            myMarkers: [],
            firstFillDone: false,
            snow_images: [],
            normal_images: []
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControlZoom
    },
    computed: {
        dynamicSize () {
            return [this.iconSize, this.iconSize * 1.15];
        },
        dynamicAnchor () {
            return [this.iconSize / 2, this.iconSize * 1.15];
        }
    },
    mounted() {
        this.getData();
        document.getElementById('zoomLevel').value = this.getZoom();
        document.getElementById('latitude').value = this.getLatLng().lat
        document.getElementById('longitude').value = this.getLatLng().lng
    },
    methods: {
        zoomUpdated(zoom) {
            document.getElementById('zoomLevel').value = zoom;
        },
        centerUpdated(center) {
            document.getElementById('latitude').value = center.lat
            document.getElementById('longitude').value = center.lng;
        },
        getData(){
            let url = '/nodes?map=1';
            let imei = this.getImei();
            if(imei.length > 0){
                url = url + '&imei='+imei;
            }
            this.axios
                .get(url)
                .then(response => {
                    this.nodes = response.data.nodes;
                    this.snow_images = response.data.snow_images;
                    this.normal_images = response.data.normal_images;
                })
        },
        openSidebar(node) {
            this.$refs['sidebar_' + node.imei][0].loadNodeData();
            $('#sidebar_' + node.imei).modal();
        },
        getQueryParams(){
            let uri = window.location.href.split('?');
            let getVars = {};
            if(uri.length == 2) {
                let vars = uri[1].split('&');

                let tmp = '';
                vars.forEach(function (v) {
                    tmp = v.split('=');
                    if (tmp.length == 2)
                        getVars[tmp[0]] = tmp[1];
                });
            }
            return getVars;
        },
        getZoom(){
            let params = this.getQueryParams();
            let defaultZoom = 4;
            if(params.hasOwnProperty('zoom')){
                defaultZoom = parseInt(params['zoom']);
            }
            return defaultZoom;
        },
        getLatLng(){
            let params = this.getQueryParams();
            let defaultLat = 48.66355805385297;
            let defaultLng = 11.310959509687457;
            if(params.hasOwnProperty('lat')){
                defaultLat = parseFloat(params['lat']);
            }
            if(params.hasOwnProperty('lng')){
                defaultLng = parseFloat(params['lng']);
            }
            return latLng(defaultLat, defaultLng)
        },
        getImei(){
            let params = this.getQueryParams();
            let defaultImei = '';
            if(params.hasOwnProperty('imei')){
                defaultImei = params['imei'];
            }
            return defaultImei
        }
    },
};
</script>

<style scoped>
.map {
    height: 100vh;
    width: 100%;
    position:relative;
    z-index: 1;
}
</style>
