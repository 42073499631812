<template>
    <div class="container justify-content-start">
        <!-- Modal -->
        <div class="modal left" :id="'sidebar_'+ node.imei" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">

                <div class="modal-content">
                    <div class="loading">
                        <loading :active.sync="isLoading"
                                 :is-full-page="fullPage"
                                 :height="300"
                                 :width="300"
                                 :color="'rgba(91,75,217,0.78)'"
                                 :loader="'dots'"
                        ></loading>
                    </div>

                    <div class="modal-body">

                        <div class="row" v-if="sensorReadings[0]" style="margin-left:0px; margin-right: 0px;">
                            <div class="sidebar-img" :style="{ 'background-image': 'url(' + sensorImg + ')'}">
                                <img class="exitButton" :src="exitImg" alt="exit" @click="closeModal">
                            </div>
                        </div>

                        <div v-if="errorInRetrieving" class="row" style="margin-top: 9rem;">
                            <img class="exitButton" :src="exitImg" alt="exit" @click="closeModal">
                            <p style="text-align: center;font-size:35px;font-weight: bold;">{{this.translations['data_error']}}</p>
                            <img :src="notFoundImage" alt="Data  not found" style="margin-left: -8px; max-width: 100%; max-height: auto;">
                            <p style="text-align: center;font-size:35px;font-weight: bold;">{{this.translations['error_try_later']}}</p>
                            <a class="btn btn-primary btn-large btn-block mx-4" style="margin-top:10px" @click="closeModal"><b>Επιστροφή</b></a>
                        </div>


                        <div v-if="sensorReadings[0]" id="sidebarBlueBox" class="row text-center pt-3" style="background-color: cornflowerblue; height: auto; color: white">
                            <div class="container row pb-3">
                                <div class="col"><h6>{{ node.lat.toString().substr(0,2) }}° {{ node.lat.toString().substr(3,2)}}' N   {{ node.lng.toString().substr(0,2)}}° {{ node.lng.toString().substr(3,2)}}' W</h6></div>
                                <div class="col"><h5> {{ node.name || '-'}} </h5></div>
                                <div class="col">
                                    <h6 v-if="sensorReadings[0]" class="mr-2" style="position: absolute; right: 0;">
                                    {{this.translations['latest_measurement']}}: {{ sensorReadings[0]['CREATED_AT'].substr(0, 16) }}
                                    </h6>
                                </div>
                            </div>
                            <div class="container row">
                                <div class="col">
                                    <h1 class="minMaxText" style="margin-top:10px; margin-left:0px; margin-right:0px;">{{ sensorReadings[0]['temperature'] }}
                                        <small>°C</small></h1>
                                    <h6>{{this.translations['temperature']}}</h6>
                                    <h6 v-if="minMaxReadings"> 24 h min: <b>{{ minMaxReadings.min_temp }}</b> <small>°C</small> |
                                        max: <b>{{ minMaxReadings.max_temp }}</b> <small>°C</small> </h6>
                                </div>
                                <div class="col">
                                    <h1 class="minMaxText" style="margin-top: 10px">
                                        {{ sensorReadings[0]['humidity'] }}<small>%</small></h1>
                                    <h6>{{this.translations['humidity']}}</h6>
                                    <h6 v-if="minMaxReadings">24 h min: <b>{{ minMaxReadings.min_humidity }}</b> <small>%</small> |
                                        max: <b>{{ minMaxReadings.max_humidity }}</b> <small>%</small> </h6>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="sensorReadings[0]" style="margin-left:0px; margin-right:0px;">
                            <h4 class="m-2"> {{this.translations['temperature_last_24h']}} </h4>
                            <chart v-if="chartReadings[0]" type="temperature" :chartReadings="this.chartReadings"></chart>
                        </div>
                        <div class="row" style="margin-left:21px; margin-right: 21px;">
                            <ul v-if="sensorReadings[0]" class="list-group list-group-flush" style="width: 100%;">
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-thermometer-half" viewBox="0 0 16 16">
                                        <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z"/>
                                        <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z"/>
                                    </svg>
                                    {{this.translations['temperature']}}
                                    <span class="float-right">
                                            {{ sensorReadings[0]['temperature'] }} <small>°C</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-droplet-half" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                                        <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"/>
                                    </svg>
                                    {{this.translations['humidity']}}
                                    <span class="float-right">
                                        {{ sensorReadings[0]['humidity'] }} <small>%</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning-charge" viewBox="0 0 16 16">
                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"/>
                                    </svg>
                                    {{this.translations['uv_radiation']}}
                                    <span class="float-right">
                                        {{ sensorReadings[0].uv_index }} <small>UV index</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    {{this.translations['wind_speed']}}
                                    <span class="float-right">
                                        {{ beaufort }} <small>Beaufort</small>
                                        {{ sensorReadings[0]['wind_speed'] }}  <small>km/h</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-compass" viewBox="0 0 16 16">
                                        <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                        <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z"/>
                                    </svg>
                                    {{this.translations['wind_direction']}}
                                    <span class="float-right">
                                        {{ windDirection }}
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    {{this.translations['gust_speed']}}
                                    <span class="float-right">
                                        {{ gustBeaufort }} <small>Beaufort</small>
                                        {{ sensorReadings[0]['gust_speed'] }} <small>km/h</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightbulb" viewBox="0 0 16 16">
                                        <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
                                    </svg>
                                    {{this.translations['light_intensity']}}
                                    <span class="float-right">
                                        {{ sensorReadings[0]['light_intensity'] }} <small>W/m<sup>2</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item"  v-if="sensorReadings[0].relative_pressure === undefined">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning-charge" viewBox="0 0 16 16">
                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"/>
                                    </svg>
                                    {{this.translations['absolute_pressure']}}
                                    <span class="float-right">
                                        {{ sensorReadings[0].pressure }} <small> hPa</small>
                                    </span>
                                </li>
                                <li class="list-group-item" v-if="sensorReadings[0].relative_pressure !== undefined">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning-charge" viewBox="0 0 16 16">
                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"/>
                                    </svg>
                                    {{this.translations['relative_pressure']}}
                                    <span class="float-right">
                                        {{ sensorReadings[0].relative_pressure }} <small> hPa</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-drizzle" viewBox="0 0 16 16">
                                        <path d="M4.158 12.025a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm-3.5 1.5a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 1 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm.747-8.498a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 11H13a3 3 0 0 0 .405-5.973zM8.5 2a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 2z"/>
                                    </svg>
                                    {{this.translations['rainfall_24h']}}
                                    <span class="float-right">
                                         {{ (Math.round(sensorReadings[0]['rainfall'] * 100) / 100).toFixed(2) }}<small> mm</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-drizzle" viewBox="0 0 16 16">
                                        <path d="M4.158 12.025a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm-3.5 1.5a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 1 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm.747-8.498a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 11H13a3 3 0 0 0 .405-5.973zM8.5 2a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 2z"/>
                                    </svg>
                                    {{this.translations['rainfall_7d']}}
                                    <span class="float-right">
                                         {{ (Math.round(sensorReadings[0]['rainfall_7d'] * 100) / 100).toFixed(2) }}<small> mm</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-thermometer-half" viewBox="0 0 16 16">
                                        <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z"/>
                                        <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z"/>
                                    </svg>
                                    {{this.translations['dew_point']}}
                                    <span class="float-right">
                                         {{ sensorReadings[0]['dew_point'] }}<small> °C</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    {{this.translations['wind_chill']}}
                                    <span class="float-right">
                                         {{ sensorReadings[0]['wind_chill'] }}<small> °C</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning-charge" viewBox="0 0 16 16">
                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"/>
                                    </svg>
                                    {{this.translations['heat_index']}}
                                    <span class="float-right">
                                         {{ sensorReadings[0]['heat_index'] }}<small> °C</small>
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <a v-if="sensorReadings[0]" class="btn btn-primary btn-large btn-block" :href="this.details_24h_url" style="margin-top:10px; margin-left:12px; margin-right:12px; width:calc(100% - 24px);"><b>{{this.translations['details']}} ({{this.translations['24h']}})</b></a>
                        <a v-if="sensorReadings[0]" class="btn btn-primary btn-large btn-block" :href="this.details_7d_url" style="margin-top:10px; margin-left:12px; margin-right:12px; width:calc(100% - 24px);"><b>{{this.translations['details']}} ({{this.translations['7d']}})</b></a>
                    </div>
                    </div>
                </div><!-- modal-content -->
            </div><!-- modal-dialog -->
        </div><!-- modal -->
</template>

<script>
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

import exit from "../../../public/images/close.png";
import notFoundIm from "../../../public/images/satellite.png"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {latLng} from "leaflet";

let images = [];


export default {
    props: ['node', 'snow_images', 'normal_images'],
    components: {
        Loading
    },
    data() {
        return {
            details_24h_url:'/details/',
            details_7d_url:'/details/7d/',
            translations:[],
            sensorReadings: [],
            chartReadings: [],
            minMaxReadings: [],
            beaufort: 0,
            gustBeaufort: 0,
            windDirection: '',
            uv: 0,
            sensorImg: images[Math.floor(Math.random() * images.length)],
            exitImg: exit,
            notFoundImage: notFoundIm,
            isLoading: false,
            fullPage: false,
            errorInRetrieving: false

        }
    },
    mounted() {
        images = this.normal_images;
        if(this.node.has_snow){
            images = this.snow_images;
        }
        this.sensorImg = images[Math.floor(Math.random() * images.length)]
        if(this.node.image.length > 0){
            this.sensorImg = '/images/all/' + this.node.image[Math.floor(Math.random() * this.node.image.length)];
        }

        this.translations = window.translations;
        this.details_24h_url = this.details_24h_url + this.node.name;
        this.details_7d_url = this.details_7d_url + this.node.name
        let queryParams = '';
        queryParams += this.getLatLng();
        queryParams += this.getZoom();
        queryParams += this.getImei();
        if(queryParams.length > 0){
            queryParams = queryParams.substring(1);
            queryParams = '?' + queryParams;
        }
        this.details_24h_url = this.details_24h_url + queryParams;
    },
    methods : {
        convertSpeedToBeaufort(wind_speed)  {
            let beaufort = 0;

            if (wind_speed >= 1.1 && wind_speed < 5.6)
                beaufort = 1;
            else if (wind_speed >= 5.6 && wind_speed < 12)
            beaufort = 2;
            else if (wind_speed >= 12 && wind_speed < 20)
            beaufort = 3;
            else if (wind_speed >= 20 && wind_speed < 29)
            beaufort = 4;
            else if (wind_speed >= 29 && wind_speed < 39)
            beaufort = 5;
            else if (wind_speed >= 39 && wind_speed < 50)
            beaufort = 6;
            else if (wind_speed >= 50 && wind_speed < 62)
            beaufort = 7;
            else if (wind_speed >= 62 && wind_speed < 75)
            beaufort = 8;
            else if (wind_speed >= 75 && wind_speed < 89)
            beaufort = 9;
            else if (wind_speed >= 89 && wind_speed < 103)
            beaufort = 10;
            else if (wind_speed >= 103 && wind_speed < 118)
            beaufort = 11;
            else if (wind_speed >= 118)
            beaufort = 12;

            return beaufort;
        },
        convertWindDirection(value) {
            return Math.round((value % 360) / 22.5) + 1;
        },
        compass(value) {
            let compass = [
                this.translations['-'],
                this.translations['N'],
                this.translations['NNE'],
                this.translations['NE'],
                this.translations['ENE'],
                this.translations['E'],
                this.translations['ESE'],
                this.translations['SE'],
                this.translations['SSE'],
                this.translations['S'],
                this.translations['SSW'],
                this.translations['SW'],
                this.translations['WSW'],
                this.translations['W'],
                this.translations['WNW'],
                this.translations['NW'],
                this.translations['NNW'],
                this.translations['N']
            ];

            return compass[value];
        },
        convertUV($value) {
            if ($value >= 0 && $value <= 432) return 0;
            else if ($value >= 433 && $value <= 851) return 1;
            else if ($value >= 852 && $value <= 1210) return 2;
            else if ($value >= 1211 && $value <= 1570) return 3;
            else if ($value >= 1571 && $value <= 2017) return 4;
            else if ($value >= 2018 && $value <= 2450) return 5;
            else if ($value >= 2451 && $value <= 2761) return 6;
            else if ($value >= 2762 && $value <= 3100) return 7;
            else if ($value >= 3101 && $value <= 3512) return 8;
            else if ($value >= 3513 && $value <= 3918) return 9;
            else if ($value >= 3919 && $value <= 4277) return 10;
            else if ($value >= 4278 && $value <= 4650) return 11;
            else if ($value >= 4651 && $value <= 5029) return 12;
            else if ($value >= 3919 && $value <= 65535) return 13;
        },
        convertLux(value) {
            return Math.round(value * 0.0079, 2);
        },
        loadNodeData() {
            this.isLoading = true;

            this.axios
                .get('/sensor-data', {
                    params: {
                        imei: this.node.imei,
                        offset: new Date().getTimezoneOffset()
                    }
                })
                .then(response => {
                    if (response.data === 'error' ) {
                        this.isLoading = false;
                        this.errorInRetrieving = true
                        return;
                    }

                    this.chartReadings = response.data;
                    this.sensorReadings = response.data;
                    this.sensorReadings[0]['uv_power'] = this.convertUV(this.sensorReadings[0]['uv_power']);
                    this.sensorReadings[0]['light_intensity'] = this.convertLux(this.sensorReadings[0]['light_intensity']);
                    this.windDirection = this.compass(this.convertWindDirection(this.sensorReadings[0]['wind_direction']));
                    this.beaufort = this.convertSpeedToBeaufort(this.sensorReadings[0]['wind_speed'])
                    this.gustBeaufort = this.convertSpeedToBeaufort(this.sensorReadings[0]['gust_speed'])
                    this.sensorReadings[0]['rainfall'] = Math.round(this.sensorReadings.map(function(o) {
                        return o.rainfall;
                    }).reduce((a,b) => a + b, 0) * 100) /100;


                    this.minMaxReadings['max_temp']        = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['min_temp']        = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['max_humidity']        = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.humidity; }));
                    this.minMaxReadings['min_humidity']        = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.humidity; }));

                    // console.log(this.chartReadings);
                    // console.log(this.minMaxReadings);
                    // console.log(this.node)
                    this.isLoading = false;


                })
        },
        closeModal() {
            $("#sidebar_" + this.node.imei).modal("toggle");
        },
        getQueryParams(){
            let uri = window.location.href.split('?');
            let getVars = {};
            if(uri.length == 2) {
                let vars = uri[1].split('&');

                let tmp = '';
                vars.forEach(function (v) {
                    tmp = v.split('=');
                    if (tmp.length == 2)
                        getVars[tmp[0]] = tmp[1];
                });
            }
            return getVars;
        },
        getZoom(){
            let params = this.getQueryParams();
            let defaultZoom = '';
            if(params.hasOwnProperty('zoom')){
                defaultZoom = '&zoom='+params['zoom'];
            }
            return defaultZoom;
        },
        getLatLng(){
            let params = this.getQueryParams();
            let defaultLat = '';
            let defaultLng = '';
            if(params.hasOwnProperty('lat')){
                defaultLat = '&lat='+params['lat'];
            }
            if(params.hasOwnProperty('lng')){
                defaultLng = '&lng='+params['lng'];
            }
            return defaultLng + defaultLat;
        },
        getImei(){
            let params = this.getQueryParams();
            let defaultImei = '';
            if(params.hasOwnProperty('imei')){
                defaultImei = '&imei='+params['imei'];
            }

            return defaultImei
        }
    }
}
</script>

<style type="text/css" scoped>
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 180%;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 0px 0px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
    left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
    right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    display: block;
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}

demo {
    padding-top: 60px;
    padding-bottom: 110px;
}

.btn-demo {
    margin: 15px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    background-color: #FFFFFF;
}

.btn-demo:focus {
    outline: 0;
}

.demo-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background-color: #212121;
    text-align: center;
}

.demo-footer > a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
}
.sidebar-img {
    width: 100%;
    height:40vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
}
.sidebar-img span {
    align-self: flex-end;
}
.exitButton {
    display:none;
}
.minMaxText {
    font-size:3.25rem;
}

@media all and (max-width: 800px) {
    .modal.left .modal-content,
    .modal.right .modal-content {
        width: 100%;
    }
    .modal-dialog {
        width: auto !important;
    }
    .modal-content .loading {
        width:100px;
    }
    .exitButton {
        width: 25px;
        position: fixed;
        right: 22px;
        top: 19px;
        display: block;
        cursor: pointer;
        z-index: 1000;
        background-color: white;
        padding: 6px;
        border-radius: 6px;
        opacity: 0.6;
    }
    .minMaxText {
        font-size: 2.75rem;
    }
    #sidebarBlueBox {
        justify-content: center;
        margin-left: 0px;
        margin-right: 0px;
    }
}
</style>
